import React from "react";
import img_language_e from './asset/images/en/img_language_e.png'
import img_main from './asset/images/en/img_main.png'
import img_7777usdt from './asset/images/en/img_7777usdt.png'
import img_issue1 from './asset/images/en/img_issue1.png'
import img_issue2 from './asset/images/en/img_issue2.png'
import img_issue3 from './asset/images/en/img_issue3.png'
import img_issue4 from './asset/images/en/img_issue4.png'
import img_issue5 from './asset/images/en/img_issue5.png'
import img_issue6 from './asset/images/en/img_issue6.png'
import img_issue7 from './asset/images/en/img_issue7.png'
import img_issue8 from './asset/images/en/img_issue8.png'
import img_form from './asset/images/en/img_form.png'
import img_card1 from './asset/images/en/img_card1.png'
import img_card2 from './asset/images/en/img_card2.png'
import img_card3 from './asset/images/en/img_card3.png'
import img_card4 from './asset/images/en/img_card4.png'
import img_book from './asset/images/en/img_book.png'
import logo from './asset/images/en/logo.png'
import img_colour from './asset/images/en/img_colour.png'
import img_33card from './asset/images/en/img_33card.png'
import MenuModal from './MenuModal'
import { Link } from 'react-router-dom';

const Home = ({
  isEnglish,
  toggle
}) => {
  const renderHeader = () => {
    return (
      <header className="header">
        <a href="https://ovobsc.ovo.space" target="_blank">
          <img src={logo} width="62px" height="22px" alt="logo" />
        </a>
        <div className="header-right">
        <Link to="/en">
            <button className={`button ${isEnglish ? 'isSelected' : ''}`}>English</button>
          </Link>
          <Link to="/" onClick={toggle}>
            <button className={`button ${isEnglish ? '' : 'isSelected'}`}>日本語</button>
          </Link>
          {/* <MenuModal>
            <img src={img_language_e} alt="img_language_e" />
          </MenuModal> */}
        </div>
      </header>
    )
  }
  return (
    <div className="home">
      {renderHeader()}
      <section className="first-section">
        <div>
          <img className="img" src={img_main} alt="img_main" />
        </div>
        
        <div className="first-section-title-wrapper">
          <p className="first-section-title">Nyathees Fusion BIG updates</p>
          <p className="first-section-title">Play to Earn with More Fun!</p>
        </div>
        <a href="https://ovobsc.ovo.space/#/collection/nyathees" target="_blank">
          <div className="first-section-img-wrapper">
            <p className="first-section-img-text">Get my Mystery Box</p>
          </div>
        </a>
        <div className="first-section-desc">
          <div className="base-text">
            <p>Play To Earn with FUN </p>
            <p>Get 5★ Nyathees, <span className="text-bold">claim $7777!</span></p>
            <p>Open Mystery Boxes and get USDT Nyathees for</p>
            <p><span className="text-bold">maximum 100x rewards </span> Fusion your Nyathees! </p>
            <p>Fusion your Nyathees to claim <span className="text-bold"> special rights</span> </p>
            <p>Special tickets for a total of <span className="text-bold"> $25,000 </span> hiding in Mystery Boxes!</p>
          </div>
          <img src={img_7777usdt} width="200px" height="200px" alt="img_7777usdt" />
        </div>
      </section>


      {/* 需要包裹一层div  设置width="100%" 才能缩放 */}
      <section className="second-section-wrapper">
        <div className="second-section">
          <h1 className="second-section-title">Mystery Box</h1>
          <p className="second-section-desc">The 2nd round of Nyathees Mystery Box Card Pool is below:</p>
          <div className="second-section-img-wrapper">
            <div><img src={img_issue1} alt={img_issue1} width="100%"  /></div>
            <div><img src={img_issue2} alt={img_issue2} width="100%" /></div>
            <div><img src={img_issue3} alt={img_issue3} width="100%" /></div>
            <div><img src={img_issue4} alt={img_issue4} width="100%" /></div>
          </div>

          <div className="second-section-img-wrapper">
            <div><img src={img_issue5} alt={img_issue5}  width="100%"  /></div>
            <div><img src={img_issue6} alt={img_issue6}  width="100%" /></div>
            <div><img src={img_issue7} alt={img_issue7}  width="100%" /></div>
            <div><img src={img_issue8} alt={img_issue8}  width="100%" /></div>
          </div>
        </div>
      </section>
      

      <section className="third-section">
        <h1 className="third-section-title">New FUSION rules</h1>
        <p className="third-section-sub-title">※Fusion function is under development, COMING SOON!</p>
        <span className="third-section-text-big">Rules</span>
        <div className="third-section-desc">
          <img className="img third-section-img" src={img_form} alt={img_form} />
          <p className="third-section-text">*The Fusion of different stars requires a certain amount of ST (Stamina), details will be released in the official</p>
          <p className="third-section-text"> announcement.</p>
        </div>
      </section>

      <section className="fourth-section-wrapper">
        <div className="fourth-section">
          <div className="fourth-section-desc">
            <p>5★ Nyathees can only be obtained through Fusion. Those who get a 5★ Nyathees will be rewarded with
              <span className="text-red"> $7,777</span>
            </p>
            <p>5★ Nyathees owners can upgrade their NFT to AR and get special experience with Nyathees!</p>
          </div>
          <div className="fourth-section-img-wrapper">
            <div><img className="img" src={img_card1} alt={img_card1} /></div>
            <div><img className="img" src={img_card2} alt={img_card2} /></div>
          </div>
        </div>
      </section>

      <section className="fifth-section-wrapper">
        <div className="fifth-section">
          <img src={img_card3} width="96px" height="128px" alt={img_card3} />
          <div className="fifth-section-desc fifth-section-desc-right">
            <p>When the Fusion of 3★ to fails, you will get an additional AR</p>
            <p>upgrade NFT (5★ Nyathees can be used).</p>
            <p><span className="base-text-big ">Upgrade your Nyathees to AR!</span></p>
            <p>Use your AR upgrade NFT to upgrade 5★ Nyathees to</p>
            <p>AR(Augmented reality)!</p>
            <p>The most advanced technique of NFT display will be online in OVO in the</p>
            <p> future. You can use your phone to check your special AR NFT asset and</p>
            <p> show it to your friends in Metaverse!</p>
          </div>
        </div>
        <div className="fifth-section">
        <p className="fifth-section-desc fifth-section-desc-left">When the Fusion of 3★ to 4★ success, you will get a special Creative Canvas NFT
          Use Creative Canvas to create your unique NFT and win rewards in contests!!</p>
          <img src={img_card4} alt={img_card4} width="96px" height="128px" />
        </div>
        
      </section>

      <section className="sixth-section-wrapper">
        <div className="sixth-section">
           <h1 className="sixth-section-title">Collection of Nyathees</h1>
           <div className="sixth-section-img-wrapper">
             <img src={img_book} alt={img_book} width="144px" height="93px" />
             <div className="sixth-section-desc">
              <p>Collection of Nyathees</p>
              <p>Collect 33 types of "Story of Nyathees” to get 1 NFT e-book that</p>
              <p>includes all story content. Those who get the e-book will get extra special rewards!</p>
             </div>
           </div>
           <p className="sixth-section-desc">Collect all 77 types of Nyathees to get 1 limited legendary Nyathees NFT (most valuable Nyathees NFT ever).This hidden NFT will have the most powerful hash rate in mining.</p>
        </div>
        
      </section>
      

      <section className="seventh-section">
        <img src={img_33card} width="100%" />
        <div className="seventh-section-img-text">33 types of "Story of Nyathees”</div>
      </section> 

      <section className="eighth-section-wrapper">
       <div className="eighth-section">
       <h1 className="eighth-section-title">Creative Canvas</h1>

       <div className="eighth-section-desc">
        <p>Ways to get Creative Canvas</p>
        <p>1.Marketplace</p> 
        <p>2.A successful Fusion of 3★ to 4★ </p>
        <p>*Creative Canvas can not be used in Fusion.</p>
        
        <p>What is Creative Canvas：</p> 
        
        <p>Users can use Creative Canvas to create their own NFTs, and they can submit their works to the regularly held "Nyathees Creation Contest", and get high bonuses.</p>
       </div>

       <img className="img" src={img_colour} alt={img_colour} width="358px" height="93px" />
       </div>
      </section>

      <section className="ninth-section">
      <a href="https://ovobsc.ovo.space/#/collection/nyathees" target="_blank">
        <div className="ninth-section-img-wrapper">
          
          <p className="ninth-section-img-text">Get my Mystery Box</p>
          
        </div>
        </a>
      </section>
    </div>
  )
}

export default Home