import React, { useState } from 'react';
import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import './App.css';
import Home from './Home'
import HomeJp from './HomeJp'

function App() {
  const [isEnglish, setIsenglish] = useState(false);
  const toggle = () => setIsenglish(!isEnglish);

  return (
    <div className="App">
      <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<HomeJp toggle={toggle} isEnglish={isEnglish} />} />
          <Route path="/en" element={<Home isEnglish={isEnglish} toggle={toggle} />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;
